var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"title":"Absenzenwesen","color":"info"}},[_c('template',{slot:"extension"},[_c('v-tabs',{attrs:{"background-color":"info darken-1","slider-color":"white","align-with-title":!_vm.$vuetify.breakpoint.xs}},[_c('v-tab',{attrs:{"to":{
          name: 'AbsencesPending',
        }}},[_vm._v("Pendenzen")]),_c('v-tab',{attrs:{"to":{
          name: 'AbsencesOverview',
        }}},[_vm._v("Übersicht")]),_c('v-tab',{attrs:{"to":{
          name: 'AbsencesBalance',
        }}},[_vm._v("Abschluss")]),_c('v-tab',{attrs:{"to":{
          name: 'AbsencesInformation',
        }}},[_vm._v("Informationen")])],1)],1),(_vm.$route.path.includes('overview') || _vm.$route.path.includes('pending'))?_c('v-btn',{attrs:{"color":"success","fab":"","fixed":"","bottom":"","right":""},on:{"click":_vm.addJustification}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-container',{staticClass:"mb-4"},[_c('v-row',{staticClass:"align-center justify-center"},[_c('SchoolClassPicker',{attrs:{"outlined":"","text":"","disabled":_vm.$route.name == 'AbsencesOverviewStudent'},model:{value:(_vm.schoolClass),callback:function ($$v) {_vm.schoolClass=$$v},expression:"schoolClass"}}),_c('v-spacer'),(_vm.$route.path.includes('overview'))?_c('TermPicker',{attrs:{"outlined":"","text":"","range":[-3, 1]},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}}):_vm._e(),_c('v-spacer'),_c('ReportButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'AbsencesOverviewStudent'),expression:"$route.name == 'AbsencesOverviewStudent'"}],attrs:{"text":"","icon":_vm.$vuetify.breakpoint.xs,"outlined":!_vm.$vuetify.breakpoint.xs,"color":"info","parameters":{
          id: _vm.$route.params.id,
          term: _vm.term ? _vm.term.id : 0,
        },"resource":"report/absencescommittee"}},[_vm._v(_vm._s(_vm.$vuetify.breakpoint.xs ? "" : _vm.$vuetify.breakpoint.mobile ? "Meldung" : "Meldung häufiger Absenzen"))])],1)],1),_c('router-view',{attrs:{"schoolClass":_vm.schoolClass,"term":_vm.term}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }